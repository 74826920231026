<template>
    <table>
        <tr v-for="(item, index) in definitions" :key="index" :style="{verticalAlign: (item.type === 3) ? 'top': 'middle'}">
            <th class="pb-50"><span class="font-weight-bold mr-2">{{ item.name }}</span></th>
            <td class="pb-50" v-if="item.type === 0"> {{ (item.formatFn) ? item.formatFn(data[item.key]) : data[item.key] }} </td>
            <td class="pb-50" v-else-if="item.type === 1">
                <b-badge v-if="data[item.key]" variant="success">
                    Yes
                </b-badge>
                <b-badge v-else variant="danger">
                    No
                </b-badge>
            </td>
            <td v-else-if="item.type === 2">
                <table>
                    <tr v-for="(arrayItem, arrayIndex) in data[item.key]" :key="arrayIndex">
                        {{arrayItem}}
                    </tr>
                </table>
            </td>
            <td v-else-if="item.type === 3">
                <table>
                    <tr>
                        <th class="pr-3" v-for="(header, headerIndex) in item.headers" :key="headerIndex">{{header.name}}</th>
                    </tr>
                    <tr v-for="(arrayItem, arrayIndex) in data[item.key]" :key="arrayIndex">
                        <td class="pr-3" v-for="(header, headerIndex) in item.headers" :key="headerIndex">{{arrayItem[header.key]}}</td>
                    </tr>
                </table>
            </td>
            <td v-else-if="item.type === 4" class="pb-50">
                <b-badge :variant="(item.colorFn) ? item.colorFn(data[item.key]) : 'primary'">
                    {{ item.formatFn(data[item.key]) }}
                </b-badge>
            </td>
            <td v-else-if="item.type === 5" class="pb-50">
                <template v-if="data[item.key] === '0001-01-01T00:00:00Z'">
                    Unknown
                </template>
                <template v-else>
                    {{moment(data[item.key]).format('HH:mm YYYY/MM/DD')}}
                </template>
            </td>
        </tr>
    </table>
</template>
<script>
    import {BBadge} from 'bootstrap-vue'

    export default {
        components: {
            BBadge
        },
        props: {
            definitions: {
                type: Array,
                required: true
            },
            data: {
                type: Object,
                required: true
            }
        }
    }
</script>