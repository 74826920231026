<template>
    <div>
        <b-row>
            <b-col lg="4">
                <b-overlay :show="!dataLoaded">
                    <b-card title="User" style="overflow: scroll">
                        <BasicOverview v-if="!editEnabled" :data="user" :definitions="overviewDefinitions"/>
                        <div v-else>

                            <b-form-group>
                                <label>Enabled</label>
                                <b-form-checkbox v-model="editObject.enabled" switch>Enabled</b-form-checkbox>
                            </b-form-group>

                            <b-form-group>
                                <label>Username</label>
                                <b-form-input v-model="editObject.username"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Name</label>
                                <b-form-input v-model="editObject.name"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Last name</label>
                                <b-form-input v-model="editObject.last_name"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Phone number</label>
                                <b-form-input v-model="editObject.phone_number"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Password</label>
                                <b-form-input type="password" v-model="editObject.password"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Assigned distribution vehicle</label>
                                <v-select
                                    v-model="editObject.assigned_vehicle_id"
                                    :reduce="type => type.id"
                                    label="name"
                                    :options="vehicles"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Roles</label>
                                <v-select
                                    v-model="editObject.roles"
                                    :reduce="type => type.id"
                                    label="name"
                                    multiple
                                    :options="roles"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Areas</label>
                                <v-select
                                    v-model="editObject.areas"
                                    :reduce="type => type.id"
                                    label="name"
                                    multiple
                                    :options="areas"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Territories</label>
                                <v-select
                                    v-model="editObject.territories"
                                    :reduce="type => type.id"
                                    label="name"
                                    multiple
                                    :options="territories"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Districts</label>
                                <v-select
                                    v-model="editObject.districts"
                                    :reduce="type => type.id"
                                    label="name"
                                    multiple
                                    :options="districts"
                                />
                            </b-form-group>
                        </div>

                        <hr/>

                        <b-button v-if="!editEnabled && $hasPermission($permissions.EditUsers)" variant="warning" @click="openEdit" class="mr-1">
                            <feather-icon icon="EditIcon" class="mr-50"/>
                            <span class="align-middle">Edit</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="success" @click="saveUser" class="mr-1">
                            <feather-icon icon="SaveIcon" class="mr-50"/>
                            <span class="align-middle">Save</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="editEnabled = false">
                            <feather-icon icon="XIcon" class="mr-50"/>
                            <span class="align-middle">Cancel</span>
                        </b-button>

                        <b-button v-if="editEnabled && $hasPermission($permissions.RemoveUsers)" variant="danger" @click="removeUser" class="float-right mr-1">
                            <feather-icon icon="Trash2Icon" class="mr-50"/>
                            <span class="align-middle">Remove</span>
                        </b-button>
                    </b-card>
                </b-overlay>
            </b-col>
        </b-row>

        <div class="mb-2">
            <b-button variant="warning" @click="$router.push({ path: `/user/survey_results/${$route.params.id}`})" class="mr-1">
                <!-- <feather-icon icon="EditIcon" class="mr-50"/> -->
                <span class="align-middle">Survey Results</span>
            </b-button>
        </div>
       

        <b-row>
            <b-col lg="12">
                <UserSales :user-id="$route.params.id"/>
            </b-col>
            <b-col lg="12">
                <UserSalesLoaction :user-id="$route.params.id"/>
            </b-col>
            <b-col lg="12">
                <!-- <UserSurveys :user-id="$route.params.id"/> -->
            </b-col>
        </b-row>
    </div>
</template>
<script>

    import {BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow, BFormCheckbox} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import UserSales from '@/views/User/UserSale'
    import UserSalesLoaction from '@/views/User/UserLocations'
    import BasicOverview from '@/views/components/BasicOverview'
    // import UserSurveys from '@/views/User/UserSurveys'


    export default {
        components: {
            BasicOverview,
            BCard,
            BOverlay,
            vSelect,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            // UserSurveys,
            UserSales,
            UserSalesLoaction
        },
        data() {
            return {
                dataLoaded: false,
                user: {},

                roles: [],
                vehicles: [],
                areas: [],
                territories: [],
                districts: [],


                overviewDefinitions: [
                    { key: 'enabled', name: 'Enabled', type: 1 },
                    { key: 'username', name: 'Username', type: 0 },
                    { key: 'name', name: 'Name', type: 0 },
                    { key: 'last_name', name: 'Last name', type: 0 },
                    { key: 'phone_number', name: 'Phone number', type: 0 },
                    { key: 'assigned_vehicle', name: 'Distribution vehicle', type: 0, formatFn: (name) => ((name && name.length > 0) ? name : 'None') },
                    { key: 'role_names', name: 'Roles', type: 0, formatFn: (data) => ((data) ? data.join(', ') : '') },
                    { key: 'area_names', name: 'Areas', type: 0, formatFn: (data) => ((data) ? data.join(', ') : '') },
                    { key: 'territory_names', name: 'Territories', type: 0, formatFn: (data) => ((data) ? data.join(', ') : '') },
                    { key: 'district_names', name: 'Districts', type: 0, formatFn: (data) => ((data) ? data.join(', ') : '') }
                ],

                editEnabled: false,
                editObject: {}
            }
        },
        methods: {
            openEdit() {
                this.editObject = JSON.parse(JSON.stringify(this.user))
                this.editEnabled = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/user/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.user = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const rolesLoadPromise = this.$http.get('/api/management/v1/role')
                rolesLoadPromise.then(function(response) {
                    thisIns.roles = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const vehiclesLoadPromise = this.$http.get('/api/management/v1/distribution_vehicle')
                vehiclesLoadPromise.then(function(response) {
                    thisIns.vehicles = [{name: 'None', id: thisIns.nullObjectId}].concat(response.data)
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const areasLoadPromise = this.$http.get('/api/management/v1/area')
                areasLoadPromise.then(function(response) {
                    thisIns.areas = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const territoriesLoadPromise = this.$http.get('/api/management/v1/territory')
                territoriesLoadPromise.then(function(response) {
                    thisIns.territories = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const districtsLoadPromise = this.$http.get('/api/management/v1/district')
                districtsLoadPromise.then(function(response) {
                    thisIns.districts = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, rolesLoadPromise, vehiclesLoadPromise, areasLoadPromise, territoriesLoadPromise, districtsLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveUser() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/user/${  this.$route.params.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('User saved')
                    thisIns.editEnabled = false
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            async removeUser() {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this user?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/user/${  this.$route.params.id}`)
                    this.$printSuccess('User removed')
                    this.$router.go(-1)

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)                    
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>